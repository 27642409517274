<style>
.add-owner {
    float: right;
}
</style>
<template>
    <div>

        <!-- Form Modal -->
        <b-modal ref="myModalTicket" centered no-close-on-backdrop no-close-on-esc :hide-footer=true size="lg">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 class="text-center">Bales Details on Warehouse</h5>
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>


            <!-- Data Form in Modal Body -->
            <template #default="{ }">

                <div style="max-height: 400px; overflow-y: auto;">
                    <table class="table mt-10" style="margin-top: 20px;">
                        <thead>
                            <tr>
                                <th scope="col">SN</th>
                                <th scope="col">Barcode</th>
                                <th scope="col">HGrade</th>
                                <th scope="col">NGrade</th>
                                <th scope="col" class="text-center">Mass</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(ticket, index)  in bales">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ ticket.barcode }}</td>
                                <td><span v-if="ticket.houseGrade != null">{{ ticket.houseGrade.name }}</span></td>
                                <td></td>
                                <td class="text-center"><span v-if="ticket.houseGrade">{{ ticket.houseGrade.mass
                                        }}</span></td>

                            </tr>
                        </tbody>




                    </table>
                </div>

                <!-- <b-row style="font-weight:bold;">
                    <b-col cols="6" class="float-right">
                        <label>Total Bales: <strong>{{ bales.length }}</strong></label>
                    </b-col>
                    <b-col cols="6" class="float-left">
                        <label>Total Mass: <strong>{{ totalWeight.toFixed(3) }}</strong></label>
                    </b-col>
                </b-row> -->
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="warehouse.id === null">Register Warehouse</h5>
                <h5 v-if="warehouse.id !== null">Edit Warehouse</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="partition_id" label="Layout">
                                    <b-form-select size="md" v-model="warehouse.layout_id"
                                        @change="changeLayout($event)">
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="layout in layouts" :key="layout.id"
                                            :value="layout">
                                            {{ layout.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" v-if="partitions.length > 0">
                                <b-form-group labe-for="partition_id" label="What to add?">
                                    <b-form-select size="md" v-model="warehouse.partition"
                                        @change="changePartition($event)">
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="partition in partitions" :key="partition.id"
                                            :value="partition">
                                            {{ partition.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>

                            <b-col v-if="warehouse.partition_id != null">
                                <b-form-group labe-for="warehouse_id" label="On Which Warehouse?">
                                    <b-form-select size="md" v-model="warehouse.warehouse_id"
                                        @change="changeWarehouse($event)">
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id"
                                            :value="warehouse.id">
                                            {{ warehouse.name }} - {{ warehouse.code }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>

                        </b-row>
                        <b-row v-if="warehouse.partition_id != null">
                            <b-col cols="3">
                                <b-form-group labe-for="length" label="Length">
                                    <validation-provider #default="{ errors }" name="Length" rules="">
                                        <b-form-input id="length" name="length" v-model="warehouse.length"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.length">{{
            serverErrors.length[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="3">
                                <b-form-group labe-for="width" label="Width">
                                    <validation-provider #default="{ errors }" name="Width" rules="">
                                        <b-form-input id="width" name="width" v-model="warehouse.width"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.width">{{
            serverErrors.width[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="3">
                                <b-form-group labe-for="height" label="Height">
                                    <validation-provider #default="{ errors }" name="height" rules="">
                                        <b-form-input id="height" name="height" v-model="warehouse.height"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.height">{{
            serverErrors.height[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="3">
                                <b-form-group labe-for="capacity" label="Capacity">
                                    <validation-provider #default="{ errors }" name="capacity" rules="">
                                        <b-form-input id="code" name="capacity" v-model="warehouse.capacity"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.capacity">{{
            serverErrors.capacity[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col v-for="(selected, index) in formSelections" :key="selected.level" cols="6">
                                <b-form-group :label-for="selected.level" :label="selected.label">
                                    <validation-provider #default="{ errors }" :name="selected.label" rules="required">
                                        <b-form-select :id="selected.level" :name="selected.level" size="md"
                                            :state="errors.length > 0 ? false : null" v-model="selected.model"
                                            @change="changeFormSelection(selected.model, index)">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="location in selected.locations"
                                                :key="location.id" :value="location">
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
            serverErrors.location_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="3">
                                <b-form-group labe-for="code" label="CODE">
                                    <validation-provider #default="{ errors }" name="CODE" rules="">
                                        <b-form-input id="code" name="code" v-model="warehouse.code"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.code">{{
            serverErrors.code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="9">
                                <b-form-group labe-for="name" label="Name">
                                    <validation-provider #default="{ errors }" name="Name" rules="">
                                        <b-form-input id="name" name="name" v-model="warehouse.name"
                                            :state="errors.length > 0 ? false : null" size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{
            serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="warehouse.partition_id == null">
                            <b-col>
                                <b-button size="sm" class="add-owner mb-1" variant="outline-primary"
                                    @click="addElement()">Add Owner</b-button>

                            </b-col>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Owner</th>
                                        <th width="20%">Share </th>
                                        <th width="5%"> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="element, index in  warehouse.owners" :key="index">
                                        <td scope="row">
                                            <div>
                                                <b-form-select v-model="element.type"
                                                    :options="options"></b-form-select>

                                            </div>
                                        </td>
                                        <td>

                                            <p v-if="element.type == 1">{{ clientName.name }}</p>


                                            <b-form-select size="md" v-model="element.owner" v-if="element.type == 2">
                                                <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                                <b-form-select-option v-for="society in societies" :key="society.id"
                                                    :value="society.id">
                                                    {{ society.name.toUpperCase() }} {{ society.code }}
                                                </b-form-select-option>
                                            </b-form-select>

                                            <b-form-select size="md" v-model="element.owner" v-if="element.type == 3">
                                                <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                                <b-form-select-option v-for="company in companies" :key="company.id"
                                                    :value="company.id">
                                                    {{ company.name.toUpperCase() }}
                                                </b-form-select-option>
                                            </b-form-select>
                                        </td>
                                        <td>
                                            <div>
                                                <b-form-input id="share" name="share" size="md" v-if="element.type == 1"
                                                    v-model="sharePerc.count" disabled width="80%" />
                                                <b-form-input id="share" name="share" v-model="element.share" size="md"
                                                    v-if="element.type > 1" @input="handlePerc(element.share)" />

                                            </div>

                                        </td>
                                        <td>
                                            <b-button variant="outline-danger" size="sm"
                                                @click="removeRow(index, element.share)">
                                                <feather-icon icon="TrashIcon" size="11"
                                                    class="text-danger stroke-current" />
                                            </b-button>
                                        </td>
                                        <!-- {{ element }} -->
                                    </tr>
                                    <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <!-- <pre>
                            {{ warehouse }}
                        </pre> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="3" class="mb-md-0 mb-2" v-for="(toSelect, index) in selections"
                        :key="toSelect.level">
                        <label>{{ toSelect.label }}</label>
                        <b-form-select size="md" v-model="toSelect.model"
                            @change="changeFilters(toSelect.model, index)">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="location in toSelect.locations" :key="location.id"
                                :value="location">
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeCreateForm()">
                                <span class="text-nowrap">Add Warehouse</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive
                :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(location)="data">
                    {{ data.item.location.name.toUpperCase() }}
                </template>


                <template #cell(offloadedBales)="data">
                   {{ data.item.stock.stockBales.toLocaleString() }}
                </template>

                <template #cell(offloadedBalesMass)="data">
                    {{ data.item.stock.stockMass.toLocaleString() }}
                </template>

                <template #cell(transitBales)="data">
                    {{ data.item.stock.transitBales.toLocaleString() }}
                </template>



                <template #cell(transitBalesMass)="data">
                    {{ data.item.stock.transitMass.toLocaleString() }}
                </template>


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="viewBales(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">View Bales</span>
                        </b-dropdown-item>


                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import warehousesStoreModule from '@/views/cromis/warehouse/warehouse/warehousesStoreModule'
import useWarehousesList from '@/views/cromis/warehouse/warehouse/useWarehousesList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider,
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const companies = ref([])
        const societies = ref([])
        const children = ref([])
        const layouts = ref([])
        const bales = ref([])
        const partitions = ref([])
        const warehouses = ref([])
        const myModalTicket = ref(null)
        const warehouseOb = ref()
        const totalShare = ref(100);
        const warehouse = ref({
            id: null,
            location_id: null,
            code: null,
            name: null,
            partition_id: null,
            layout_id: null,
            partition: null,
            warehouse_id: null,
            length: null,
            width: null,
            height: null,
            capacity: null,
            owners: [
                {
                    owner_id: null,
                    type: null,
                    share: null,
                }
            ]
        })

        const formField = reactive([
            { owner: null, type: null, share: null }
        ]);


        let sharePerc = reactive({ count: 100 })

        const addElement = () => {
            warehouse.value.owners.push({ owner: '', type: '', share: '' })
            warehouse.value.owners.forEach(owner => {
                totalShare.value = totalShare.value - parseInt(owner.share, 10) || 0;
            });

            if (warehouse.value.owners.length > 0) {

                warehouse.value.owners.forEach(el => {
                    if (el.type == 1) {
                        el.owner = JSON.parse(localStorage.getItem('userData')).client.id
                        el.share = sharePerc.count
                    }
                });

            }
        }

        const removeRow = (index, share) => {
            if (warehouse.value.owners.length > 1) {
                sharePerc.count = parseInt(sharePerc.count) + parseInt(share)
                warehouse.value.owners.splice(index, 1)
            }
        }


        const handlePerc = (data) => {
            // Handle input change
            if (sharePerc.count == NaN || sharePerc.count == null) {
                sharePerc.count = 0;
                warehouse.value.owners.forEach(el => {
                    sharePerc.count += parseInt(el.share)
                });

                sharePerc.count = 100 - sharePerc.count
            }
            sharePerc.count = parseInt(sharePerc.count) - parseInt(data)
        }

        const CROMIS_STORE_MODULE_NAME = 'cromis-warehouse'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, warehousesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-warehouse/levels')
                .then(response => {
                    levels.value = response.data.levels[0]

                    selections.value.push({
                        level: levels.value.name.toLowerCase(),
                        model: null,
                        label: levels.value.name,
                        child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                        locations: [],
                    })

                    formSelections.value.push({
                        level: levels.value.name.toLowerCase(),
                        model: null,
                        label: levels.value.name,
                        child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                        locations: [],
                    })

                    let list = levels.value.children

                    while (list.length > 0) {
                        children.value.push(list[0])
                        if (list[0].children > 0) {
                            selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                            formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                        }
                        else {
                            selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                            formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                        }

                        list = list[0].children
                    }
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-warehouse/layouts')
                .then(response => {
                    layouts.value = response.data.layouts

                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-warehouse/locations')
                .then(response => {
                    populateSelections(response.data.locations)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-warehouse/companies')
                .then(response => {
                    companies.value = response.data.companies
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-warehouse/societies')
                .then(response => {
                    societies.value = response.data.societies
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        const changeFilters = async (location, index) => {
            if (!location) {
                for (let i = index + 1; i < selections.value.length; i++) {
                    selections.value[i].locations.splice(0)
                    selections.value[i].model = null
                }

                if (index > 0) {
                    locationFilter.value = selections.value[index - 1].model.id
                }
                else {
                    locationFilter.value = null
                }
            }
            else {
                if (selections.value[index + 1]) {
                    selections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    selections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    selections.value[index + 1].model = null
                }

                locationFilter.value = selections.value[index].model.id
                if (index === selections.value.length - 1) {
                    warehouse.value.location_id = locationFilter.value
                }
            }
        }

        const changeFormSelection = async (location, index) => {
            if (!location) {
                for (let i = index + 1; i < formSelections.value.length; i++) {
                    formSelections.value[i].locations.splice(0)
                    formSelections.value[i].model = null
                }

                warehouse.value.location_id = null
            }
            else {
                warehouse.value.location_id = null

                if (formSelections.value[index + 1]) {
                    formSelections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    formSelections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    formSelections.value[index + 1].model = null
                }

                if (index === formSelections.value.length - 1) {
                    warehouse.value.location_id = location.id
                }
            }
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useWarehousesList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            let location = item.location ? item.location : null
            let selectedLocations = []
            while (location) {
                selectedLocations.push({ level: location.level.name.toLowerCase(), id: location.id })
                location = location.parent
            }

            for (let i = 0; i < formSelections.value.length; i++) {
                for (let n = 0; n < selectedLocations.length; n++) {
                    if (formSelections.value[i].level === selectedLocations[n].level) {
                        for (let x = 0; x < formSelections.value[i].locations.length; x++) {
                            if (formSelections.value[i].locations[x].id === selectedLocations[n].id) {
                                formSelections.value[i].model = formSelections.value[i].locations[x]

                                if ((i + 1) < formSelections.value.length) {
                                    formSelections.value[i + 1].locations = formSelections.value[i].locations[x].children
                                }
                            }
                        }
                    }
                }
            }

            warehouse.value = {
                id: item.id,
                location_id: item.location ? item.location.id : null,
                code: item.code,
                name: item.name,
                owners: item.owners,
                partition_id: item.partition_id,
                warehouse_id: item.warehouse_id,
                length: item.length,
                width: item.width,
                height: item.height,
                capacity: item.capacity,
                layout_id: item.layout_id,
                partition: item.partition,
            }

            myModal.value.show()
        }

        const viewBales = (item) => {
            bales.value = item.bales

            myModalTicket.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            warehouse.value = {
                id: null,
                location_id: null,
                code: null,
                name: null,
                partition_id: null,
                warehouse_id: null,
                layout_id: null,
                partition: null,
                length: null,
                width: null,
                height: null,
                capacity: null,
                owners: [
                    {
                        owner_id: JSON.parse(localStorage.getItem('userData')).client.id,
                        type: 1,
                        share: 100,
                    }
                ]
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (warehouse.value.id === null || warehouse.value.id === 0)
                handleCreate()
            else
                handleUpdate(warehouse.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            if (warehouse.value.partition_id == null) {
                warehouse.value.partition_id = warehouse.value.partition != null? warehouse.value.partition.id : null
            }

            await store.dispatch('cromis-warehouse/create', warehouse.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Market center has been added successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const changeLayout = async (event) => {
            partitions.value = event.partition
        }

        const changePartition = async (event) => {
            warehouse.value.partition_id = null;

            if (event.partition_id == null) {
                warehouseOb.value = event
            } else {
                warehouse.value.partition_id = event.partition_id
            }

            if (event.partition_id != null) {
                await store.dispatch('cromis-warehouse/list')
                    .then(response => {
                        // let childrenWarehouse
                        warehouses.value = response.data.warehouses


                    })
                    .catch(error => {
                        console.log('got error', error)
                    })
            }
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-warehouse/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to warehouse center ${response.data.code} ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the Warehouse?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Warehouse is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }

        const remove = async (id) => {
            await store.dispatch('cromis-warehouse/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }


        return {
            // Data
            selections,
            formSelections,
            myModalTicket,
            levels,
            children,
            warehouse,
            sharePerc,
            selectedOwner: null,
            addedElement: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: 'Select Owner' },
                { value: '1', text: 'Company' },
                { value: '2', text: 'Societies' },
                { value: '3', text: 'Third Part' }
            ],
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            changeFilters,
            changeFormSelection,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            addElement,
            formField,
            removeRow,
            handlePerc,
            totalShare,
            companies,
            societies,
            layouts,
            changeLayout,
            partitions,
            changePartition,
            warehouses,
            viewBales,
            bales
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>