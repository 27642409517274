import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('warehouses', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        get: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.get('warehouses', { params: {
                    "warehouse_id": params.id
                }} )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        listChildren: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('warehouses', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        
        show: (cntx, { id }) => {
            return axios.get(`warehouses/${id}`)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    throw error; 
                });
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('warehouses', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`warehouses/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`warehouses/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        levels: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('levels', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        companies: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('companies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        stackGrades: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('partition_grades', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        layouts: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('layouts', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        societies: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('societies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        groups: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('groups', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
